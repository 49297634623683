/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* for sidenav to take a whole page */
html,
body {
    margin: 0;
    height: 100%;
}

body,
a,
p,
.mat-card-title,
.mat-card-subtitle,
.mat-card-content,
.mat-raised-button,
.mat-button {
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica,
        Arial, sans-serif;
    letter-spacing: -0.021em;
}

body,
a,
p,
.mat-card-subtitle,
.mat-card-content,
.mat-raised-button,
.mat-button {
    font-weight: 400;
    line-height: 1.5;
    font-kerning: normal;
}

.mat-card-title {
    text-align: center;
    padding: 10px;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
    font-kerning: normal;
}

a,
p,
.mat-card-subtitle {
    font-size: 17px;
}

.content {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
}

@media only screen and (min-width: 768px) {
    .content {
        padding-left: 30%;
        padding-right: 30%;
    }
}
